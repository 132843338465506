<template>
    <div class="head">
        <div class="layout-logo">打印模板管理</div>
        <div class="layout-nav">
            <Dropdown v-if="$context.isLogin" @on-click="handleMenu">
                <a href="javascript:void(0)" >
                    <span v-html="$context.user.title"></span>
                    <Icon type="ios-arrow-down"> </Icon>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem name="51">退出</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <a @click="showLoginModal" v-else >
                登录
            </a>
        </div>
        <Modal v-model="showLogin" title="登录" transfer footer-hide>
            <Form :model="login" :rules="loginRule" >
                <FormItem prop="username">
                    <Input type="text" v-model="login.username" placeholder="用户名">
                        <Icon type="ios-person-outline" slot="prepend"></Icon>
                    </Input>
                </FormItem>
                <FormItem prop="password" >
                    <Input type="password" v-model="login.password" @on-enter="handleLogin" placeholder="密码">
                        <Icon type="ios-lock-outline" slot="prepend"></Icon>
                    </Input>
                </FormItem>
                <FormItem style="text-align: center;">
                    <Button type="primary" class="login-button" @click="handleLogin">登录</Button>
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>

<script>

var getLoginUser = function(){
    var response = $.ajax({
                url: "/api/loginInfo",
                async: false,
                type: 'POST'
            });
    var result = response.status == 200 || response.statusText == 'OK';
    if(result){
        try{
            return JSON.parse(response.responseText);
        }catch(e){
            return null;
        }
        
    }else{
        return null;
    }
}

export default {
    data(){
        var user = getLoginUser();
        if(user!=null){
            this.$context.user=user;
            this.$context.isLogin = true;
        }
        return {
            loginRule:{
                username: [
                    { required: true, message: '用户名不能为空', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' }
                ]
            },
            login:{
                username:'',
                password:''
            },
            showLogin:false
        }
    },
    methods:{
        handleLogin:function(){
            var response = $.ajax({
                url: "/api/login",
                async: false,
                type: 'POST',
                data: {
                    username: this.login.username,
                    password: this.login.password
                }
            });

            var result = response.status == 200 || response.statusText == 'OK';
            let message = "";
            if (response.status == 504) {
                message = "服务启动中!"
            } else if (!response.responseText || response.responseText == "") {
                message = '用户名与密码不匹配，请重新输入'
            } else if (response.status == 401) {
                message = response.responseText;
            }
            if(result){
                this.$context.user = getLoginUser();
                this.$context.isLogin = true;
                this.showLogin = false;
                $msgCenter.sendMessage(this.$context.msgKey,this.$context.user);
                this.$Message.info('登录成功');
            }else{
                this.$Message.error(message);
            }

        },
        showLoginModal:function(){
            this.showLogin = true;
        },
        handleMenu:function(name){
            var that = this;
            if(name=='51'){
                $.ajax({
                    url: "/api/logout",
                    async: true,
                    type: 'POST',
                    success:function(){
                        that.$context.user={
                            title:''
                        };
                        that.$context.isLogin = false;
                        that.$Message.info('退出成功');
                        $msgCenter.sendMessage(that.$context.msgKey,that.$context.user);
                        that.$forceUpdate()
                    },
                    error:function(){
                        that.$Message.error('退出失败');
                    }
                })
            }
            
        }
    }
}
</script>

<style scoped>
.head{
    width: 1180px;
    position: relative;
    height: 100%;
    margin: 0 auto;
}

.head .layout-logo{
    width: auto;
    height: auto;
    background: none;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 2px;
    left: 5px;
    font-size: 25px;
    color: #FFFFFF;
}


.head .layout-nav{
    width: auto;
    position: absolute;
    right: 50px;
    line-height: 21px !important;
    top: 22px;
}

.head .layout-nav a{
    color: #FFFFFF;
    width: 100px;
    outline: none;
}

.head .layout-nav a:hover{
    color: #9CEE66;
    cursor: pointer;
}

.login-button{
    width: 200px;
}
</style>