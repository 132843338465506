var group = () => import('../components/group/group.vue');
var groupDownload = () => import('../components/group/groupDownload.vue');
var template = () => import('../components/template/template.vue');
var templateDownload = () => import('../components/template/templateDownload.vue');

var templateEdit = ()=> import('../components/template/templateEdit.vue')

var routes = [
    {
        path: '/',
        name: 'group',
        component: group
    },
    {
        path: '/groupDownload/:groupId',
        name: 'groupDownload',
        component: groupDownload
    },
    {
        path: '/template/:groupId',
        name: 'template',
        component: template
    },
    {
        path: '/templateDownload/:templateId',
        name: 'templateDownload',
        component: templateDownload
    },
    {
        path: '/templateEdit/:templateId',
        name: 'templateEdit',
        component: templateEdit
    }
]

export default routes;


