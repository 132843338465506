
function generateUUID() {
    var d = new Date().getTime();
    if(window.performance && typeof window.performance.now === "function"){
        d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
}

function MessageCenter(){
    let msgLis = {};

    this.addListener = function(key,lis){
        let lises = msgLis[key];
        if(lises==null){
            msgLis[key] = lises = {};
        }
        let id  = generateUUID();
        lises[id]=lis;   
        return function(){
            delete lises[id];
            console.log(msgLis);
        }
    };

    this.sendMessage=function(key,msg){
        let lises = msgLis[key];
        if(lises==null){
            return;
        }
        for(let id in lises){
            lises[id](msg);
        }
    }
}

export default MessageCenter;