<template>
  <Layout class="fly-layout">
      <Header class="fly-header">
          <F-Header></F-Header>
      </Header>
      <Content>
          <router-view></router-view>
      </Content>
      <Footer class="layout-footer-center">
          2017-2020 &copy;飞天小毛驴
          <a href="https://beian.miit.gov.cn/" target="blank">京ICP备17052114号-2</a>
        </Footer>
  </Layout>
</template>

<script>

  import FHeader from './components/head.vue'
  export default {
      components:{
          "F-Header":FHeader
      },
      data: function(){
          return {}
      }
  };
</script>

<style scoped>
  .fly-layout{
      width: 100%;
      height: 100%;
      background: none !important;
  }

  .fly-header{
      background-color: #FF7755 !important;
  }

  .layout-footer-center{
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 999;
  }

  .fly-layout>.ivu-layout-content{
      position: absolute;
      width: 100%;
      top: 64px;
      bottom: 21px;
  }

  .fly-layout>.ivu-layout-content{
      position: absolute;
      width: 100%;
      top: 64px;
      bottom: 21px;
      overflow-y:auto;
  }
  .fly-layout>.ivu-layout-footer{
      padding: 0;
  }
</style>
