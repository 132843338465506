


import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import $ from 'jquery'
import routes from './router/router.js';
import MessageCenter from './router/message.js'

import 'view-design/dist/styles/iview.css';
import ViewUI from 'view-design';


Vue.use(VueRouter);
Vue.use(ViewUI);


Vue.prototype.$jq = $;
window.$ = $;


let router = new VueRouter({
  routes
});
window.$router = router;

window.$msgCenter = new MessageCenter();
Vue.prototype.$msgCenter = window.$msgCenter;


Vue.config.productionTip = false

Vue.prototype.$context = {
  user:{
    title:''
  },
  isLogin:false,
  msgKey:'loginStateChange'
};

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
